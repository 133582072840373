import React from 'react';
import './App.css';
import Aux from './hoc/Auxiliary/Auxiliary';
import {Route, Switch } from 'react-router-dom';
import HomePage from './containers/HomePage/HomePage';
import NavigationItems from './components/NavigationItems/NavigationItems';
import Footer from './components/Footer/Footer';


function App() {
  return (
    <Aux>
    <NavigationItems/>
    <Switch>
        <Route path='/' component={HomePage}/>
    </Switch>
    <Footer/>
    </Aux>
  );
}

export default App;
