import React from 'react';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import SocialMediaBar from '../SocialMedia/SocialMediaBar';
import styles from './Footer.module.css';
import logo from '../../assets/images/radiantLogoSm1.png'

const footer = () => (
    <Aux >
        <footer>
        <div className={styles.Footer}>
        <SocialMediaBar />
            <div className="pt-3 pb-3 row m-auto justify-content-center" >   
                
                {/* <a  href='/'>Home</a>
                <a  href='/About-Us'>About</a>
               <a  href='/Booking'>Booking</a>
               <a  href='/Tour'>Tour</a>
               <a  href='/Gallery'>Gallery</a> */}
            </div>
            <p className="mb-0">Powered by: <img className={styles.logo} src={logo} alt='logo' /> Radiant Solutions</p>
        </div>

        </footer>
    </Aux>

);


export default footer;