import React from 'react';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import styles from './SocialMediaBar.module.css';
import { FaFacebook, FaYoutube, FaInstagram, FaLinkedin} from 'react-icons/fa';

const socialMediaBar = () => (
    <Aux>
       
        <div className={styles.SocialMediaBar}>
            
            
                    <a href='https://www.facebook.com/Radiant-Solutions-100177961821206'><FaFacebook style={{fontSize: `30px`}} /></a>
                
                    <a href='https://www.instagram.com/radiantsolutionsllc/?hl=en'><FaInstagram style={{fontSize: `30px`}}/></a>
              
                    <a  href='https://www.youtube.com/user/benjaminelser18'><FaYoutube style={{fontSize: `30px`}}/></a>

                    <a  href='https://www.linkedin.com/company/radiant-solutions-llp'><FaLinkedin style={{fontSize: `30px`}}/></a>

                    
               
              
        </div>
    </Aux>

)

export default socialMediaBar;