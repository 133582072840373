import React, {Component} from 'react';
import {Button, Form } from 'react-bootstrap';
import styles from './BookingForm.module.css';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import axios from 'axios';
import Spinner from '../UI/Spinner/Spinner';
import checkmark from '../../assets/images/checkmark.png';
import {BsPerson} from 'react-icons/bs';
import {GoCalendar} from 'react-icons/go';
import {AiOutlineCar} from 'react-icons/ai';

// import{ init } from 'emailjs-com';
// init("user_PAZvsmA0E2kduYIbaTHse");


class BookingForm extends Component {
    state= {
        loading: false,
        submitted: false,
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        company: "",
        budget: "",
        message: "",
        OrgId: ""
    }

    
  componentDidMount() {
    this.setState({submitted: false})
  }

  resetForm = () => {
    this.setState({submitted: false})
  }

  firstNameInputHandler = (event) => {
    const updatedFirstName = event.target.value;
    this.setState({firstName: updatedFirstName})
  }
  lastNameInputHandler = (event) => {
    const updatedLastName = event.target.value;
    this.setState({lastName: updatedLastName})
  }
  emailInputHandler = (event) => {
    const updatedEmail = event.target.value;
    this.setState({email: updatedEmail})
  }
  phoneNumberInputHandler = (event) => {
    const updatedPhoneNumber = event.target.value;
    this.setState({phoneNumber: updatedPhoneNumber})
  }
  
  yearInputHandler = (event) => {
    const updatedYear = event.target.value;
    this.setState({year: updatedYear})
  }
  budgetInputHandler = (event) => {
    const updatedBudget = event.target.value;
    this.setState({budget: updatedBudget})
  }
  companyInputHandler = (event) => {
    const updatedCompany = event.target.value;
    this.setState({company: updatedCompany})
  }
  messageInputHandler = (event) => {
    const updatedMessage = event.target.value;
    this.setState({message: updatedMessage})
  }

  RequestHandler = (event) => {
  
    event.preventDefault();
    this.setState({loading: true})
    const templateId = 'template_76do9v9';
    this.sendFeedback(templateId, {message_html: this.state.message, firstName: this.state.firstName, email: this.state.email, lastName: this.state.lastName, company: this.state.company, budget: this.state.budget, phoneNumber: this.state.phoneNumber})
  }
    // let url = '';
    sendFeedback (templateId, variables) {
      window.emailjs.send(
        "service_6zz046q", templateId,
        variables)
    // axios.post(url , {
    //   FirstName: this.state.firstName,
    //   LastName: this.state.lastName,
    //   Email: this.state.email,
    //   Phone: this.state.phoneNumber,
    //   Year: this.state.year,
    //   Make: this.state.company,
    //   Model: this.state.model,
    //   Message: this.state.message,
    //   OrgId: this.state.OrgId
    // })
    .then(response => {
    //   console.log(this.state.artist)
      // console.log(this.request.Artist)
      // console.log(this.request.Title.value)
      // console.log(this.request.OrgId.value)
      console.log(response);
      this.setState({loading: false, submitted: true});
      // this.history.push('/');
      
    })
    .catch(error => {
      console.log(error);
      this.setState({loading: false})
    });
      
  
  }

    render() {
        let bookForm = (
            <div className="container">
            <Form onSubmit={this.RequestHandler} className={styles.Formdiv}>
            <Form.Group controlId="formBasicName">
                <Form.Label className='container pl-3'><BsPerson  style={{paddingRight: '5px', fontSize: '2.0rem'}}/>Request a Quote</Form.Label>
                <Form.Control value={this.firstName}
                              onChange={(event) => this.firstNameInputHandler(event)}  
                              className="w-75 m-auto" 
                              type="text" 
                              placeholder="First Name" 
                              required/>
                              
            </Form.Group>
            <Form.Group controlId="formBasicLast">
                {/* <Form.Label>Last Name</Form.Label> */}
                <Form.Control value={this.lastName}
                              onChange={(event) => this.lastNameInputHandler(event)}  
                              className="w-75 m-auto" 
                              type="text" 
                              placeholder="Last Name" 
                              required/>
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
                {/* <Form.Label>Email Address</Form.Label> */}
                <Form.Control value={this.email}
                              onChange={(event) => this.emailInputHandler(event)}  
                              className="w-75 m-auto" 
                              type="email" 
                              placeholder="Email Address" 
                              required/>
            </Form.Group>

            <Form.Group controlId="formFilechoice" >
                {/* <Form.Label>Phone Number</Form.Label> */}
                <Form.Control 
                              value={this.phoneNumber}
                              onChange={(event) => this.phoneNumberInputHandler(event)} 
                              className="w-75 m-auto" 
                              type="text" 
                              placeholder='Phone Number'  
                              required/>
                              
            </Form.Group>

            <Form.Group controlId="formBasicYear">
                {/* <Form.Label><AiOutlineCar style={{paddingRight: '5px', fontSize: '2.0rem'}}/>Vehicle Information </Form.Label> */}
                <Form.Control value={this.company}
                              onChange={(event) => this.companyInputHandler(event)}  
                              className="w-75 m-auto" 
                              type="text" 
                              placeholder="Company Name" 
                              />
                              
            </Form.Group>
            <Form.Group controlId="formBasicMake">
                {/* <Form.Label>Last Name</Form.Label> */}
                <Form.Control value={this.budget}
                              onChange={(event) => this.budgetInputHandler(event)}  
                              className="w-75 m-auto" 
                              type="text" 
                              placeholder="Budget" 
                              />
            </Form.Group>
      
        
            <Form.Group controlId="formBasicMessage">
                {/* <Form.Label>Extra Details (optional)</Form.Label> */}
                <Form.Control value={this.message}
                              onChange={(event) => this.messageInputHandler(event)}  
                              className="w-75 m-auto" 
                              as ="textarea" 
                              rows ='3'
                              placeholder="Custom Message" 
                              />
            </Form.Group>
            <Form.Text className="text text-center">
                We look forward to working with you!
                </Form.Text>
                <div className='text-center'>
                    <Button  style={{backgroundColor: 'rgb(170, 27, 170)'}} variant="success" type="submit"  >
                      Submit
                  </Button>
                </div>
           
        </Form>
   
    </div>
        )

        if(this.state.loading) {
            bookForm = <Spinner />
          }
      
          if(this.state.submitted) {
            bookForm = (
                      <div className="text-center container">
                        <img className={styles.Checkmark} src={checkmark} alt="success" />
                        <p>Request Submitted Successfully</p>
                        <a className='pb-3' href='/'>Return Home</a>
                      </div>
                        )
                                  }

        return(
            <Aux>
                <div className='container text-center mt-3'>
               
                 
                 </div>
                {bookForm}
            </Aux>
        )
    }
}


  



export default BookingForm;