import React from 'react';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import styles from './Testimonials.module.css';
// import star from '../../assets/images/star1close1.jpg';
import {Carousel} from 'react-bootstrap';

const tipjar = () => (
    <Aux>
          <div className={styles.background}>
            
                {/* <img className={styles.Testimonials} src={star} alt='star'/> */}
                <div className={styles.centered}>
                <h1 className='text-center pb-3'>Testimonials</h1>
                    <Carousel indicators={false}>
                        <Carousel.Item>
                            <div className='pb-3'>
                               
                                <p>I really appreciate the insane response times on questions and the attentiveness given at all times.</p>
                                <p>-Michael</p>
                            </div>
                            <Carousel.Caption>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className='pb-3'>
                               
                                <p>Stunningly beautiful websites. </p>
                                <p>-Samantha</p>
                            </div>
                            <Carousel.Caption>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div className='pb-3'>
                              
                                <p>Completely transformed our online presence </p>
                                <p>-Mat</p>
                            </div>
                            <Carousel.Caption>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>
                   
                </div>
          </div>
    </Aux>
    
)


export default tipjar;