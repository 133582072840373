import React from 'react';
import {Navbar, Nav} from 'react-bootstrap';
import logo from '../../assets/images/radiantLogoSm1.png';
import styles from './NavigationItems.module.css';
import {Cross as Hamburger} from 'hamburger-react';


const navigationItems = () => ( 
<Navbar fixed="top" collapseOnSelect expand="lg" style={{backgroundColor: 'rgb: 0,0,0,.9'}}>
  <Navbar.Brand href="/"><img className={styles.Logo} src={logo} alt="logo"/> Radiant Solutions</Navbar.Brand>
  <Navbar.Toggle style={{border: 0}} aria-controls="responsive-navbar-nav" >
  <Hamburger rounded hideOutline={true} direction="right"/>
  </Navbar.Toggle>
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="mr-auto">
      {/* <Nav.Link href="/">Home</Nav.Link>
      <Nav.Link href="/Booking">Book the Band</Nav.Link>
      <Nav.Link href="/Gallery">Gallery</Nav.Link> */}
    </Nav>
    <Nav>
   
      <button className={styles.navButton}> <Nav.Link href="/">Home</Nav.Link></button>
      {/* <button className={styles.navButton}><Nav.Link href="/About-Us">About-Us</Nav.Link></button>
      <button className={styles.navButton}><Nav.Link href="/Gallery">Blog</Nav.Link></button> */}
      {/* <button className={styles.navButton}><Nav.Link href="/Tour">Tour</Nav.Link></button> */}
        {/* <Nav.Link  href="/Upload">Upload Content </Nav.Link> */}
    </Nav>
  </Navbar.Collapse>
</Navbar>
)

export default navigationItems;
