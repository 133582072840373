import React, {Component} from 'react';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import styles from './HomePage.module.css';
import logo from '../../assets/images/radiantLogoSm1.png';
import BookingForm from '../../components/Forms/BookingForm';
import ServicesGrid from '../../components/ServicesGrid/ServicesGrid';
import Testimonials from '../../components/Testimonials/Testimonials';

class HomePage extends Component {

    render() {
        return(
            <Aux>
                <div className={styles.HomeBackground}>
                <div className='row  pb-4'>
                <div className='col-md-1 align-self-center text-left pl-0'>
                         </div>
                    <div className='col-md-4 align-self-center text-center pr-0 pl-4'>
                        <img className={styles.Logo1} src={logo} alt='RS'/>
                        <h1 className='align-self-right ml-0' style={{ display: "inline-block", fontSize: '3em', color: 'rgb(170, 27, 170)', fontFamily: 'Acme'}}>Radiant Solutions</h1>
                        <p className='text-center container'>Delivering silicon valley quality technology to hard working small business owners</p>
                  
                    </div> 
                    <div className='col-md-3 align-self-center text-left pl-0'>
                         </div>
              
                    <div className='col-md-4'>
                        <div className='styles.HomeForm'>
                            <BookingForm/>
                        </div>
                    </div>
                    </div>

                </div>
                <div className='pt-4'>
                <ServicesGrid/>
                </div>
                
                <Testimonials/>
                

            </Aux>
        );
    }
}

    export default HomePage;