import React from 'react';
import Aux from '../../hoc/Auxiliary/Auxiliary';
import styles from './ServicesGrid.module.css';
import {FaCarAlt, FaOilCan, FaWrench} from 'react-icons/fa';
import {GiFlatTire} from 'react-icons/gi';
import {BsGear} from 'react-icons/bs';
import {FcInspection} from 'react-icons/fc';
import {FiMonitor} from 'react-icons/fi';
import {AiOutlineLineChart, AiOutlineCloudServer} from 'react-icons/ai';

const servicesGrid = () => (
    <Aux>
        <div className="pt-3 m-auto" >
           
            <div className="row m-auto d-flex justify-content-center">
               
               
                <div  className= " col-md-3 p-0 m-3 mt-0 " style={{backgroundColor: 'rgb(246,249,252)'}}>
                <a href="/Brakes" className="styles.Servicelink" style={{textDecoration: 'none', color: 'inherit'}}> 
                <div className={styles.Card}>
                    <div className='pt-4'>
                    <div className={styles.Icon}><FiMonitor/></div>
                    <div className="card-body p-0  text-center">
                    <h4 style={{ color: 'black'}} className="card-title">Website Design</h4>
                        <p style={{fontSize: '1rem', color: 'black'}} className="card-text w-75 m-auto">
                            Using a throrough discovery process, we design the perfect website for you business. 
                        </p>
                        {/* <a  href='/'>Learn More</a> */}
                        </div>
                    </div>
                </div>
                </a>
                </div>

                <div  className= " col-md-3 p-0 m-3 mt-0 " style={{backgroundColor: 'rgb(246,249,252)'}}>
                <a href="/Oil-Service" className="styles.Servicelink" style={{textDecoration: 'none', color: 'inherit'}}>
                <div className={styles.Card}>
                <div className='pt-3'>
                    <div className={styles.Icon}><AiOutlineLineChart/></div>
                    <div className="card-body p-0  text-center">
                    <h4 style={{ color: 'black'}} className="card-title">SEO</h4>
                        <p style={{  fontSize: '1rem', color: 'black'}} className="card-text w-75 m-auto">
                            If a website is never visited, does anyone know it exists? Let us help propel you to the top of the popular search engine reults. 
                        </p>
                        {/* <a href='/'>Learn More</a> */}
                    </div>
                    </div>
                </div>
                </a>
                </div>

                <div  className= " col-md-3 p-0 m-3 mt-0 " style={{backgroundColor: 'rgb(246,249,252)'}}>
                <a href="/Tires" className="styles.Servicelink" style={{textDecoration: 'none', color: 'inherit'}}>
                <div className={styles.Card}>
                <div className='pt-0'>
                    <div className={styles.Icon}><AiOutlineCloudServer/></div>
                    <div className="card-body p-0  text-center">
                    <h4 style={{ color: 'black'}} className="card-title">VIT</h4>
                        <p style={{ fontSize: '1rem', color: 'black'}} className="card-text w-75 m-auto">
                           VIT (Virtual IT) means gone are the days of IT professionals sitting in a broom closet using up valuable budget. Let us manage your IT department instead. 
                        </p>
                        {/* <a href='/'>Learn More</a> */}
                    </div>
                    </div>
                </div>
                </a>
                </div>

                





            </div>
        </div>
    </Aux>
 
)

export default servicesGrid;